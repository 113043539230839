import swiperStyle from "../styles/common/swiper.scss?inline";
export default (id, params) => ({
  id,
  params,
  init() {
    const swiperEl = document.querySelectorAll(`[data-swiper=${id}]`);
    const swiperSlides = document.querySelectorAll(
      `[data-swiper=${id}] swiper-slide`,
    );
    for (let swiper of swiperEl) {
      const swiperParams = {
        injectStyles: [swiperStyle],
        centeredSlides: false,
        navigation: true,
        pagination: {
          clickable: true,
        },
        ...params,
      };

      Object.assign(swiper, swiperParams);

      try {
        for (let slide of swiperSlides) {
          slide.classList.remove("hidden");
        }
        swiper.initialize();
      } catch (e) {
        console.log(e);
      }
    }
  },
});
