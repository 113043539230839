import Alpine from "alpinejs";
import Swiper from "swiper";
import { register } from "swiper/element/bundle";
import basicSlider from "./basic-slider";
import * as YT from "lite-youtube-embed";
import "../styles/main.scss";

register();

window.Swiper = Swiper;
window.Yt = YT;

Alpine.data("basicSlider", basicSlider);

Alpine.start();
